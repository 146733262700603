import React from 'react'
import Helmet from "react-helmet"
import {
  graphql,
  useStaticQuery,
} from "gatsby"
import favicon from './images/favicon.ico'

// // Component Imports
import Header from './layout-header'
import Footer from './layout-footer'

//Style/JS Imports
import '../node_modules/foundation-sites/dist/css/foundation.min.css';
import './css/main.css'

const Layout = (props) => {

  const data = useStaticQuery(
    graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)
  return (
    <div class="container">
      <Helmet>
        <title>{data.site.siteMetadata.title}</title>
        <link rel="icon" href={favicon} />
      </Helmet>
      <Header />
      <div class="body">
        {props.children}
      </div>
      <Footer />
    </div>
  )
}

export default Layout