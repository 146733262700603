import React from "react"

import logo from './images/logo.png'

const Footer = () => {
  return (
    <div id="footer">
      <div class="footer-top">
        <div class="sw">
          <a href="/" class="logo">
            <img src={logo} alt="Chance Murphy Design" />
          </a>
          <div class="contact">
            <div className="phone">
              <h3>Phone: </h3>
              <a href="tel:+12313131883">231-313-1883</a>
            </div>
            <div className="email">
              <h3>Email: </h3>
              <a href="mailto:chancem@umich.edu">chancem@umich.edu</a>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <div class="sw">
          <p class="copyright">© 2021 Chance Murphy Design. All Rights Reserved.</p>
        </div>
      </div>
    </div>
  )
}

export default Footer