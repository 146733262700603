import React, {useEffect} from "react"
// import {
//   // Link,
//   graphql,
//   useStaticQuery,
// } from "gatsby"
// import Img from "gatsby-plugin-image"  

import logo from './images/logo.png'

const Header = () => {

  const [scrolled, setScrolled] = React.useState(false);
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 50) {
      setScrolled(true);
    }
    else {
      setScrolled(false);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  })
  let navbarClasses = ['header-container'];
  if (scrolled) {
    navbarClasses.push('scrolled');
  }

  return (
    <div class={navbarClasses.join(" ")}>
      <header id="header">
        <div class="sw">
          <a href="/" class="logo">
            <img src={logo} alt="Chance Murphy Design" />
          </a>
          <ul class="menu">
            <li><a href="/">UX Design | Frontend Development</a></li>
            {/* <li><a href="/development/">Development</a></li>
            <li><a href="/ux-research/">UX Research</a></li> */}
          </ul>
        </div>
      </header>
    </div>
  )
}

export default Header